import { subjectAbility } from '@/constant'

/*
 * pageTitle : get from @/libs/i18n/locales/[id / en].json
 * in purpose to support multilanguage
 */

export default [
  // MODULE APPROVAL
  {
    path: '/transactions/module-approval',
    name: 'apps-transactions-module-approval-list',
    component: () => import('@/views/apps/transactions/module-approval/index.vue'),
    meta: {
      pageTitle: 'apps.transactions.moduleApproval.moduleName',
      action: 'read',
      resource: subjectAbility.TxApproval
    }
  },
  // Evaluations
  {
    path: '/transactions/evaluation', //link yang untuk di akses
    name: 'apps-transactions-evaluation-list', //penamaan link yang nantinya akan dikases
    component: () => import('@/views/apps/transactions/evaluation/index.vue'), //path file nya
    meta: {
      pageTitle: 'apps.transactions.evaluation.moduleName' //judulnya
    }
  },
  {
    path: '/transactions/evaluation-diagram', //link yang untuk di akses
    name: 'apps-transactions-evaluation-diagram', //penamaan link yang nantinya akan dikases
    component: () => import('@/views/apps/transactions/evaluation/Diagram.vue'), //path file nya
    meta: {
      pageTitle: 'apps.transactions.evaluationDiagram.moduleName' //judulnya
    }
  },
  {
    path: '/transactions/evaluation/add',
    name: 'apps-transactions-evaluation-add',
    component: () => import('@/views/apps/transactions/evaluation/Form.vue'),
    meta: {
      pageTitle: 'apps.transactions.evaluation.moduleName',
      action: 'read',
      resource: subjectAbility.TxBudgeting
    }
  },
  //edit evaluations
  {
    path: '/transactions/evaluation/detail/:id',
    name: 'apps-transactions-evaluation-detail',
    component: () => import('@/views/apps/transactions/evaluation/Form.vue'),
    meta: {
      pageTitle: 'apps.transactions.evaluation.moduleName',
      action: 'read',
      resource: subjectAbility.TxEvaluations
    }
  },
  //print evaluations
  {
    path: '/transactions/evaluation/transactions/prints-eval/:id',
    name: 'apps-transactions-purchase-eval-prints-eval',
    component: () => import('@/views/apps/transactions/evaluation/PrintEval.vue'),
    meta: {
      pageTitle: 'apps.transactions.evaluation.moduleNamePrints',
      action: 'read',
      resource: subjectAbility.TxEvaluations
    }
  },

  // CASH BANK
  {
    path: '/transactions/cashbank/request',
    name: 'apps-transactions-cashbank-request-list',
    component: () => import('@/views/apps/transactions/cashbank/request/index.vue'),
    meta: {
      pageTitle: 'apps.transactionsCashbankRequest.moduleName',
      action: 'read',
      resource: subjectAbility.TxCashbankRequest
    }
  },
  {
    path: '/transactions/cashbank/request/add',
    name: 'apps-transactions-cashbank-request-add',
    component: () => import('@/views/apps/transactions/cashbank/request/Form.vue'),
    meta: {
      pageTitle: 'apps.transactionsCashbankRequest.moduleName',
      action: 'read',
      resource: subjectAbility.TxCashbankRequest
    }
  },
  {
    path: '/transactions/cashbank/request/:id',
    name: 'apps-transactions-cashbank-request-detail',
    component: () => import('@/views/apps/transactions/cashbank/request/Form.vue'),
    meta: {
      pageTitle: 'apps.transactionsCashbankRequest.actions.read',
      action: 'read',
      resource: subjectAbility.TxCashbankRequest
    }
  },
  {
    path: '/transactions/cashbank/request-detail/:id',
    name: 'apps-transactions-cashbank-request-approval-detail',
    component: () => import('@/views/apps/transactions/cashbank/request-approval/Form.vue'),
    meta: {
      pageTitle: 'apps.transactionsCashbankRequest.actions.read',
      action: 'read',
      resource: subjectAbility.TxCashbankRequest
    }
  },
  {
    path: '/transactions/cashbank/request-approval',
    name: 'apps-transactions-cashbank-request-approval-list',
    component: () => import('@/views/apps/transactions/cashbank/request-approval/index.vue'),
    meta: {
      pageTitle: 'apps.transactionsCashbankRequest.moduleName',
      action: 'read',
      resource: subjectAbility.TxCashbankRequest
    }
  },
  {
    path: '/transactions/cashbank',
    name: 'apps-transactions-cashbank-list',
    component: () => import('@/views/apps/transactions/cashbank/index.vue'),
    meta: {
      pageTitle: 'apps.transactionsCashbank.moduleName',
      action: 'read',
      resource: subjectAbility.TxCashbank
    }
  },
  {
    path: '/transactions/cashbank/add',
    name: 'apps-transactions-cashbank-add',
    component: () => import('@/views/apps/transactions/cashbank/Form.vue'),
    children: [
      {
        path: 'cash-in/:transactionTypeCode',
        name: 'apps-transactions-cashbank-add-cash-in',
        meta: {
          pageTitle: 'apps.transactionsCashbank.actions.createCI',
          action: 'read',
          resource: subjectAbility.TxCashbank
        }
      },
      {
        path: 'cash-out/:transactionTypeCode',
        name: 'apps-transactions-cashbank-add-cash-out',
        meta: {
          pageTitle: 'apps.transactionsCashbank.actions.createCO',
          action: 'read',
          resource: subjectAbility.TxCashbank
        }
      },
      {
        path: 'bank-in/:transactionTypeCode',
        name: 'apps-transactions-cashbank-add-bank-in',
        meta: {
          pageTitle: 'apps.transactionsCashbank.actions.createBI',
          action: 'read',
          resource: subjectAbility.TxCashbank
        }
      },
      {
        path: 'bank-out/:transactionTypeCode',
        name: 'apps-transactions-cashbank-add-bank-out',
        meta: {
          pageTitle: 'apps.transactionsCashbank.actions.createBO',
          action: 'read',
          resource: subjectAbility.TxCashbank
        }
      }
    ]
  },
  {
    path: '/transactions/cashbank/add/transfer',
    name: 'apps-transactions-cashbank-add-transfer',
    component: () => import('@/views/apps/transactions/cashbank/transfer/Form.vue'),
    meta: {
      pageTitle: 'apps.transactionsCashbank.actions.createTF',
      action: 'read',
      resource: subjectAbility.TxCashbank
    }
  },
  {
    path: '/transactions/cashbank/detail/transfer/:id',
    name: 'apps-transactions-cashbank-detail-transfer',
    component: () => import('@/views/apps/transactions/cashbank/transfer/Form.vue'),
    meta: {
      pageTitle: 'apps.transactionsCashbank.actions.readTF',
      action: 'read',
      resource: subjectAbility.TxCashbankAddTransfer
    }
  },
  {
    path: '/transactions/cashbank/detail/:id/:transactionTypeCode',
    name: 'apps-transactions-cashbank-detail',
    component: () => import('@/views/apps/transactions/cashbank/Form.vue'),
    meta: {
      pageTitle: 'apps.transactionsCashbank.actions.read',
      action: 'read',
      resource: subjectAbility.TxCashbank
    }
  },
  {
    path: '/transactions/cashbank/rekon',
    name: 'apps-transactions-cashbank-rekon-list',
    component: () => import('@/views/apps/transactions/cashbank/rekon/index.vue'),
    meta: {
      pageTitle: 'apps.transactionsCashbankRekon.moduleName',
      action: 'read',
      resource: subjectAbility.TxCashbankRekon
    }
  },
  {
    path: '/transactions/cashbank/rekon/add',
    name: 'apps-transactions-cashbank-rekon-add',
    component: () => import('@/views/apps/transactions/cashbank/rekon/Form.vue'),
    meta: {
      pageTitle: 'apps.transactionsCashbankRekon.actions.createRekon',
      action: 'read',
      resource: subjectAbility.TxCashbankRekon
    }
  },
  {
    path: '/transactions/cashbank/rekon/add/:id',
    name: 'apps-transactions-cashbank-rekon-detail',
    component: () => import('@/views/apps/transactions/cashbank/rekon/Form.vue'),
    meta: {
      pageTitle: 'apps.transactionsCashbankRekon.actions.createRekon',
      action: 'read',
      resource: subjectAbility.TxCashbankRekon
    }
  },


  // DRAFT JOURNAL
  {
    path: '/transactions/draft-journal',
    name: 'apps-transactions-draft-journal-list',
    component: () => import('@/views/apps/transactions/draft-journal/index.vue'),
    meta: {
      pageTitle: 'apps.transactionsDraftJournal.moduleName',
      action: 'read',
      resource: subjectAbility.TxDraftJournal
    }
  },
  {
    path: '/transactions/draft-journal/add',
    name: 'apps-transactions-draft-journal-add',
    component: () => import('@/views/apps/transactions/draft-journal/Form.vue'),
    meta: {
      pageTitle: 'apps.transactionsDraftJournal.actions.create',
      action: 'create',
      resource: subjectAbility.TxDraftJournal
    }
  },
  {
    path: '/transactions/draft-journal/detail/:id',
    name: 'apps-transactions-draft-journal-detail',
    component: () => import('@/views/apps/transactions/draft-journal/Form.vue'),
    meta: {
      pageTitle: 'apps.transactionsDraftJournal.actions.read',
      action: 'read',
      resource: subjectAbility.TxDraftJournal
    }
  },
  {
    path: '/transactions/draft-journal/upload',
    name: 'apps-transactions-draft-journal-upload',
    component: () => import('@/views/apps/transactions/draft-journal/upload.vue'),
    meta: {
      pageTitle: 'apps.transactionsDraftJournal.actions.upload',
      action: 'create',
      resource: subjectAbility.TxDraftJournal
    }
  },
//JOURNAL TEMPLATE
{
  path: '/transactions/journal-template',
  name: 'apps-transactions-journalTemplate-list',
  component: () => import('@/views/apps/master-data/journal-template/index.vue'),
  meta: {
    pageTitle: 'apps.masterDataJournalTemplate.moduleName',
    action: 'read',
    resource: subjectAbility.TxJournalTemplate
  }
},
{
  path: '/transactions/journal-template/add',
  name: 'apps-transactions-journalTemplate-add',
  component: () => import('@/views/apps/master-data/journal-template/Form.vue'),
  meta: {
    pageTitle: 'apps.masterDataJournalTemplate.actions.create',
    action: 'create',
    resource: subjectAbility.TxJournal
  }
},
{
  path: '/transactions/journal-template/detail/:id',
  name: 'apps-transactions-journalTemplate-detail',
  component: () => import('@/views/apps/master-data/journal-template/Form.vue'),
  meta: {
    pageTitle: 'apps.transactionsJournal.actions.read',
    action: 'read',
    resource: subjectAbility.TxJournal
  }
},
  // JOURNAL
  {
    path: '/transactions/journal',
    name: 'apps-transactions-journal-list',
    component: () => import('@/views/apps/transactions/journal/index.vue'),
    meta: {
      pageTitle: 'apps.transactionsJournal.moduleName',
      action: 'read',
      resource: subjectAbility.TxJournal
    }
  },
  {
    path: '/transactions/journal/add',
    name: 'apps-transactions-journal-add',
    component: () => import('@/views/apps/transactions/journal/Form.vue'),
    meta: {
      pageTitle: 'apps.transactionsJournal.actions.create',
      action: 'create',
      resource: subjectAbility.TxJournal
    }
  },
  {
    path: '/transactions/journal/detail/:id',
    name: 'apps-transactions-journal-detail',
    component: () => import('@/views/apps/transactions/journal/Form.vue'),
    meta: {
      pageTitle: 'apps.transactionsJournal.actions.read',
      action: 'read',
      resource: subjectAbility.TxJournal
    }
  },
  {
    path: '/transactions/journal/detailreport/:id',
    name: 'apps-transactions-journalreport-detail',
    component: () => import('@/views/apps/transactions/journal/FormReport.vue'),
    meta: {
      pageTitle: 'apps.transactionsJournal.actions.read',
      action: 'read',
      resource: subjectAbility.TxJournal
    }
  },
  // SALES MODULE
  {
    path: '/transactions/sales/invoice/list',
    name: 'apps-transactions-sales-invoice-list',
    component: () => import('@/views/apps/transactions/sales/invoice/index.vue'),
    meta: {
      pageTitle: 'apps.transactions.sales.invoice.moduleName',
      action: 'read',
      resource: subjectAbility.TxSalesInvoice
    }
  },
  {
    path: '/transactions/sales/invoice/add',
    name: 'apps-transactions-sales-invoice-add',
    component: () => import('@/views/apps/transactions/sales/invoice/Form.vue'),
    meta: {
      pageTitle: 'apps.transactions.sales.invoice.moduleName',
      action: 'create',
      resource: subjectAbility.TxSalesInvoice
    }
  },
  {
    path: '/transactions/sales/invoice/edit/:id',
    name: 'apps-transactions-sales-invoice-detail',
    component: () => import('@/views/apps/transactions/sales/invoice/Form.vue'),
    meta: {
      pageTitle: 'apps.transactions.sales.invoice.moduleName',
      action: 'read',
      resource: subjectAbility.TxSalesInvoice
    }
  },
  {
    path: '/transactions/sales/invoice/prints/:id',
    name: 'apps-transactions-sales-invoice-prints',
    component: () => import('@/views/apps/transactions/sales/invoice/Print.vue'),
    meta: {
      pageTitle: 'apps.transactions.sales.invoice.moduleName',
      action: 'read',
      resource: subjectAbility.TxSalesInvoice
    }
  },

  // SALES PURCHASE
  {
    path: '/transactions/sales/payment/list',
    name: 'apps-transactions-sales-payment-list',
    component: () => import('@/views/apps/transactions/sales/payment/index.vue'),
    meta: {
      pageTitle: 'apps.transactions.sales.payment.moduleName',
      action: 'read',
      resource: subjectAbility.TxSalesPayment
    }
  },
  {
    path: '/transactions/sales/payment/invoice',
    name: 'apps-transactions-sales-payment-add-per-invoice',
    component: () => import('@/views/apps/transactions/sales/payment/FormPerInvoice.vue'),
    meta: {
      pageTitle: 'apps.transactions.sales.payment.moduleName',
      action: 'create',
      resource: subjectAbility.TxSalesPayment
    },
    props: true
  },
  {
    path: '/transactions/sales/payment/detail/:id',
    name: 'apps-transactions-sales-payment-detail',
    component: () => import('@/views/apps/transactions/sales/payment/Form.vue'),
    meta: {
      pageTitle: 'apps.transactions.sales.payment.moduleName',
      action: 'read',
      resource: subjectAbility.TxSalesPayment
    }
  },
  // PURCHASE MODULE
  // Purchase Request
  {
    path: '/transactions/purchase/request/list',
    name: 'apps-transactions-purchase-request-list',
    component: () => import('@/views/apps/transactions/purchase/request/index.vue'),
    meta: {
      pageTitle: 'apps.transactions.purchase.request.moduleName',
      action: 'read',
      resource: subjectAbility.TxPurchaseRequest
    }
  },
  {
    path: '/transactions/purchase/request/prints/:id',
    name: 'apps-transactions-purchase-request-prints',
    component: () => import('@/views/apps/transactions/purchase/request/Print.vue'),
    meta: {
      pageTitle: 'apps.transactions.purchase.request.moduleName',
      action: 'read',
      resource: subjectAbility.TxPurchaseRequest
    }
  },
  {
    path: '/transactions/purchase/request/add',
    name: 'apps-transactions-purchase-request-add',
    component: () => import('@/views/apps/transactions/purchase/request/Form.vue'),
    meta: {
      pageTitle: 'apps.transactions.purchase.request.moduleName',
      action: 'create',
      resource: subjectAbility.TxPurchaseRequest
    }
  },
  {
    path: '/transactions/purchase/request/detail/:id',
    name: 'apps-transactions-purchase-request-detail',
    component: () => import('@/views/apps/transactions/purchase/request/Form.vue'),
    meta: {
      pageTitle: 'apps.transactions.purchase.request.moduleName',
      action: 'read',
      resource: subjectAbility.TxPurchaseRequest
    }
  },
  // Purchase Request Monitoring
  // {
  //   path: '/transactions/purchase/request/monitoring/list',
  //   name: 'apps-transactions-purchase-request-monitoring-list',
  //   component: () => import('@/views/apps/transactions/purchase/request-monitoring/index.vue'),
  //   meta: {
  //     pageTitle: 'apps.transactions.purchase.request.monitoring.moduleName',
  //     action: 'read',
  //     resource: subjectAbility.TxPurchaseRequestMonitoring
  //   }
  // },
  // {
  //   path: '/transactions/purchase/request/monitoring/preview/:id',
  //   name: 'apps-transactions-purchase-request-monitoring-preview',
  //   component: () => import('@/views/apps/transactions/purchase/request-monitoring/Preview.vue'),
  //   meta: {
  //     pageTitle: 'apps.transactions.purchase.request.monitoring.moduleName',
  //     action: 'read',
  //     resource: subjectAbility.TxPurchaseRequestMonitoring
  //   }
  // },
  // Purchase Request Outstanding
  {
    path: '/transactions/purchase/request/outstanding/list',
    name: 'apps-transactions-purchase-request-outstanding-list',
    component: () => import('@/views/apps/transactions/purchase/request-outstanding/index.vue'),
    meta: {
      pageTitle: 'apps.transactions.purchase.request.outstanding.moduleName',
      action: 'read',
      resource: subjectAbility.TxPurchaseRequestOutstanding
    }
  },
  {
    path: '/transactions/purchase/request/outstanding/add',
    name: 'apps-transactions-purchase-request-outstanding-add',
    component: () => import('@/views/apps/transactions/purchase/request-outstanding/Form.vue'),
    meta: {
      pageTitle: 'apps.transactions.purchase.request.outstanding.moduleName',
      action: 'create',
      resource: subjectAbility.TxPurchaseRequestOutstanding
    }
  },
  {
    path: '/transactions/purchase/request/outstanding/detail/:id',
    name: 'apps-transactions-purchase-request-outstanding-detail',
    component: () => import('@/views/apps/transactions/purchase/request-outstanding/Form.vue'),
    meta: {
      pageTitle: 'apps.transactions.purchase.request.outstanding.moduleName',
      action: 'read',
      resource: subjectAbility.TxPurchaseRequestOutstanding
    }
  },
  // Assign Vendor
  {
    path: '/transactions/purchase/request-assign-vendor/list',
    name: 'apps-transactions-purchase-request-assign-vendor-list',
    component: () => import('@/views/apps/transactions/purchase/request-assign-vendor/index.vue'),
    meta: {
      pageTitle: 'apps.transactions.purchase.request.assignVendor.moduleName',
      action: 'read',
      resource: subjectAbility.TxPurchaseRequestAssignVendor
    }
  },
  {
    path: '/transactions/purchase/request-assign-vendor/add',
    name: 'apps-transactions-purchase-request-assign-vendor-add',
    component: () => import('@/views/apps/transactions/purchase/request-assign-vendor/Form.vue'),
    meta: {
      pageTitle: 'apps.transactions.purchase.request.assignVendor.moduleName',
      action: 'create',
      resource: subjectAbility.TxPurchaseRequestAssignVendor
    }
  },
  {
    path: '/transactions/purchase/request-assign-vendor/detail/:id',
    name: 'apps-transactions-purchase-request-assign-vendor-detail',
    component: () => import('@/views/apps/transactions/purchase/request-assign-vendor/Form.vue'),
    meta: {
      pageTitle: 'apps.transactions.purchase.request.assignVendor.moduleName',
      action: 'read',
      resource: subjectAbility.TxPurchaseRequestAssignVendor
    }
  },
  {
    path: '/transactions/purchase/request-assign-price/list',
    name: 'apps-transactions-purchase-request-assign-price-list',
    component: () => import('@/views/apps/transactions/purchase/request-assign-price/index.vue'),
    meta: {
      pageTitle: 'apps.transactions.purchase.request.assignPrice.moduleName',
      action: 'read',
      resource: subjectAbility.TxPurchaseRequestAssignPrice
    }
  },
  {
    path: '/transactions/purchase/request-assign-price/add',
    name: 'apps-transactions-purchase-request-assign-price-add',
    component: () => import('@/views/apps/transactions/purchase/request-assign-price/Form.vue'),
    meta: {
      pageTitle: 'apps.transactions.purchase.request.assignPrice.moduleName',
      action: 'create',
      resource: subjectAbility.TxPurchaseRequestAssignPrice
    }
  },
  {
    path: '/transactions/purchase/request-assign-price/detail/:id',
    name: 'apps-transactions-purchase-request-assign-price-detail',
    component: () => import('@/views/apps/transactions/purchase/request-assign-price/Form.vue'),
    meta: {
      pageTitle: 'apps.transactions.purchase.request.assignPrice.moduleName',
      action: 'read',
      resource: subjectAbility.TxPurchaseRequestAssignPrice
    }
  },
  // PURCHASE REQUEST PAYMENT ADVANCED
  {
    path: '/transactions/purchase/request-payment-advance/list',
    name: 'apps-transactions-purchase-request-payment-advance-list',
    component: () => import('@/views/apps/transactions/purchase/request-payment-advance/Index.vue'),
    meta: {
      pageTitle: 'apps.transactions.purchase.paymentVoucher.moduleName',
      action: 'read',
      resource: subjectAbility.TxPurchaseRequestPaymentAdvance
    }
  },
  {
    path: '/transactions/purchase/request-payment-advance/invoice',
    name: 'apps-transactions-purchase-request-payment-advance-add-per-invoice',
    component: () => import('@/views/apps/transactions/purchase/request-payment-advance/FormPerInvoice.vue'),
    meta: {
      pageTitle: 'apps.transactions.purchase.paymentVoucher.moduleName',
      action: 'create',
      resource: subjectAbility.TxPurchaseRequestPaymentAdvance
    },
    props: true
  },
  {
    path: '/transactions/purchase/request-payment-advance/add',
    name: 'apps-transactions-purchase-request-payment-advance-add',
    component: () => import('@/views/apps/transactions/purchase/request-payment-advance/Form.vue'),
    meta: {
      pageTitle: 'apps.transactions.purchase.paymentVoucher.moduleName',
      action: 'read',
      resource: subjectAbility.TxPurchaseRequestPaymentAdvance
    }
  },
  {
    path: '/transactions/purchase/request-payment-advance/detail/:id',
    name: 'apps-transactions-purchase-request-payment-advance-detail',
    component: () => import('@/views/apps/transactions/purchase/request-payment-advance/Form.vue'),
    meta: {
      pageTitle: 'apps.transactions.purchase.paymentVoucher.moduleName',
      action: 'read',
      resource: subjectAbility.TxPurchaseRequestPaymentAdvance
    }
  },
  // Posting AP
  {
    path: '/transactions/purchase/postingAP/list',
    name: 'apps-ap-posting-list',
    component: () => import('@/views/apps/transactions/purchase/posting-ap/index.vue'),
    meta: {
      pageTitle: 'apps.transactions.purchase.postingAp.moduleName',
      action: 'read',
      resource: subjectAbility.TxApPosting
    }
  },
  //oustanding AP yang belum di bayar
  // Posting AP
  {
    path: '/transactions/purchase/oustandingPostingNotYetPayment/list',
    name: 'apps-ap-oustandingPostingNotYetPayment-list',
    component: () => import('@/views/apps/transactions/purchase/posting-ap/indexNotYetPayment.vue'),
    meta: {
      pageTitle: 'apps.transactions.purchase.postingAp.moduleNameNotYetPayment',
      action: 'read',
      resource: subjectAbility.TxApPosting
    }
  },
  {
    path: '/transactions/purchase/oustandingPaymentHome/list',
    name: 'apps-ap-oustandingPaymentHome-list',
    component: () => import('@/views/apps/transactions/purchase/posting-ap/HomePayment.vue'),
    meta: {
      pageTitle: 'apps.transactions.purchase.postingAp.moduleNameNotYetPayment',
      action: 'read',
      resource: subjectAbility.TxApPayment
    }
  },
  {
    path: '/transactions/purchase/postingAP/detail/:id',
    name: 'apps-ap-posting-detail',
    component: () => import('@/views/apps/transactions/purchase/posting-ap/Form.vue'),
    meta: {
      pageTitle: 'apps.transactions.purchase.postingAp.singular.detailName',
      action: 'read',
      resource: subjectAbility.TxApPayment
    }
  },
  // CASH ADVANCE AP
  {
    path: '/transactions/purchase/cash-advance-ap/list',
    name: 'apps-ap-cash-advance-list',
    component: () => import('@/views/apps/transactions/purchase/cash-advance-ap/Index.vue'),
    meta: {
      pageTitle: 'apps.transactions.purchase.cashAdvanceAp.moduleName',
      action: 'read',
      resource: subjectAbility.TxApCashAdvance
    }
  },
  {
    path: '/transactions/purchase/cash-advance-ap/listImage/:id',
    name: 'apps-ap-cash-advance-listImage',
    component: () => import('@/views/apps/transactions/purchase/cash-advance-ap/listImage.vue'),
    meta: {
      pageTitle: 'apps.transactions.purchase.cashAdvanceAp.moduleName',
      action: 'read',
      resource: subjectAbility.TxApCashAdvance
    }
  },
  {
    path: '/transactions/purchase/cash-advance-ap/invoice',
    name: 'apps-ap-cash-advance-add-per-invoice',
    component: () => import('@/views/apps/transactions/purchase/cash-advance-ap/FormPerInvoice.vue'),
    meta: {
      pageTitle: 'apps.transactions.purchase.cashAdvanceAp.moduleName',
      action: 'create',
      resource: subjectAbility.TxApCashAdvance
    },
    props: true
  },
  {
    path: '/transactions/purchase/cash-advance-ap/add',
    name: 'apps-ap-cash-advance-add',
    component: () => import('@/views/apps/transactions/purchase/cash-advance-ap/Form.vue'),
    meta: {
      pageTitle: 'apps.transactions.purchase.cashAdvanceAp.moduleName',
      action: 'read',
      resource: subjectAbility.TxApCashAdvance
    }
  },
  // Purchase Order
  {
    path: '/transactions/purchase/order/list',
    name: 'apps-transactions-purchase-order-list',
    component: () => import('@/views/apps/transactions/purchase/order/index.vue'),
    meta: {
      pageTitle: 'apps.transactions.purchase.order.moduleName',
      action: 'read',
      resource: subjectAbility.TxPurchaseOrder
    }
  },
  {
    path: '/transactions/purchase/order/prints-v2/:id',
    name: 'apps-transactions-purchase-order-prints-v2',
    component: () => import('@/views/apps/transactions/purchase/order/PrintV2.vue'),
    meta: {
      pageTitle: 'apps.transactions.purchase.order.moduleName',
      action: 'read',
      resource: subjectAbility.TxPurchaseOrder
    }
  },
  {
    path: '/transactions/purchase/order/add',
    name: 'apps-transactions-purchase-order-add',
    component: () => import('@/views/apps/transactions/purchase/order/Form.vue'),
    meta: {
      pageTitle: 'apps.transactions.purchase.order.moduleName',
      action: 'create',
      resource: subjectAbility.TxPurchaseOrder
    }
  },
  {
    path: '/transactions/purchase/order/detail/:id',
    name: 'apps-transactions-purchase-order-detail',
    component: () => import('@/views/apps/transactions/purchase/order/Form.vue'),
    meta: {
      pageTitle: 'apps.transactions.purchase.order.moduleName',
      action: 'read',
      resource: subjectAbility.TxPurchaseOrder
    }
  },
  {
    path: '/transactions/purchase/order/listImage/:id',
    name: 'apps-transactions-purchase-order-listimage',
    component: () => import('@/views/apps/transactions/purchase/order/ListImage.vue'),
    meta: {
      pageTitle: 'apps.transactions.purchase.order.moduImage',
      action: 'read',
      resource: subjectAbility.TxPurchaseOrder
    }
  },
  // Purhcase Invoice
  {
    path: '/transactions/purchase/invoice/list',
    name: 'apps-transactions-purchase-invoice-list',
    component: () => import('@/views/apps/transactions/purchase/invoice/index.vue'),
    meta: {
      pageTitle: 'apps.transactions.purchase.invoice.moduleName',
      action: 'read',
      resource: subjectAbility.TxPurchaseInvoice
    }
  },
  {
    path: '/transactions/purchase/invoice/listImage/:id',
    name: 'apps-transactions-purchase-invoice-listImage',
    component: () => import('@/views/apps/transactions/purchase/invoice/listImage.vue'),
    meta: {
      pageTitle: 'apps.transactions.purchase.invoice.moduleName',
      action: 'read',
      resource: subjectAbility.TxPurchaseInvoice
    }
  },
  {
    path: '/transactions/purchase/invoice/add',
    name: 'apps-transactions-purchase-invoice-add',
    component: () => import('@/views/apps/transactions/purchase/invoice/Form.vue'),
    meta: {
      pageTitle: 'apps.transactions.purchase.invoice.moduleName',
      action: 'create',
      resource: subjectAbility.TxPurchaseInvoice
    }
  },
  {
    path: '/transactions/purchase/invoice-service/add',
    name: 'apps-transactions-purchase-invoice-service-add',
    component: () => import('@/views/apps/transactions/purchase/invoice/FormService.vue'),
    meta: {
      pageTitle: 'apps.transactions.purchase.invoice.moduleNameTwo',
      action: 'create',
      resource: subjectAbility.TxPurchaseInvoice
    }
  },
  {
    path: '/transactions/purchase/invoice/detail/:id',
    name: 'apps-transactions-purchase-invoice-detail',
    component: () => import('@/views/apps/transactions/purchase/invoice/Form.vue'),
    meta: {
      pageTitle: 'apps.transactions.purchase.invoice.moduleName',
      action: 'read',
      resource: subjectAbility.TxPurchaseInvoice
    }
  },
  {
    path: '/transactions/purchase/invoice/prints/:id',
    name: 'apps-transactions-purchase-invoice-prints',
    component: () => import('@/views/apps/transactions/purchase/invoice/Print.vue'),
    meta: {
      pageTitle: 'apps.transactions.purchase.invoice.moduleName',
      action: 'read',
      resource: subjectAbility.TxPurchaseInvoice
    }
  },
  {
    path: '/transactions/purchase/invoice/prints-v2/:id',
    name: 'apps-transactions-purchase-invoice-prints-v2',
    component: () => import('@/views/apps/transactions/purchase/invoice/PrintV2.vue'),
    meta: {
      pageTitle: 'apps.transactions.purchase.invoice.moduleName',
      action: 'read',
      resource: subjectAbility.TxPurchaseInvoice
    }
  },


  // PURCHASE PAYMENT VOUCHER
  {
    path: '/transactions/purchase/payment-voucher/list',
    name: 'apps-transactions-purchase-payment-voucher-list',
    component: () => import('@/views/apps/transactions/purchase/payment-voucher/Index.vue'),
    meta: {
      pageTitle: 'apps.transactions.purchase.paymentVoucher.moduleName',
      action: 'read',
      resource: subjectAbility.TxPurchasePaymentVoucher
    }
  },
  {
    path: '/transactions/purchase/payment-voucher/invoice',
    name: 'apps-transactions-purchase-payment-voucher-add-per-invoice',
    component: () => import('@/views/apps/transactions/purchase/payment-voucher/FormPerInvoice.vue'),
    meta: {
      pageTitle: 'apps.transactions.purchase.paymentVoucher.moduleName',
      action: 'create',
      resource: subjectAbility.TxPurchasePaymentVoucher
    },
    props: true
  },
  {
    path: '/transactions/purchase/payment-voucher/add',
    name: 'apps-transactions-purchase-payment-voucher-add',
    component: () => import('@/views/apps/transactions/purchase/payment-voucher/Form.vue'),
    meta: {
      pageTitle: 'apps.transactions.purchase.paymentVoucher.moduleName',
      action: 'read',
      resource: subjectAbility.TxPurchasePaymentVoucher
    }
  },
  {
    path: '/transactions/purchase/payment-voucher/detail/:id',
    name: 'apps-transactions-purchase-payment-voucher-detail',
    component: () => import('@/views/apps/transactions/purchase/payment-voucher/Form.vue'),
    meta: {
      pageTitle: 'apps.transactions.purchase.paymentVoucher.moduleName',
      action: 'read',
      resource: subjectAbility.TxPurchasePaymentVoucher
    }
  },
  {
    path: '/transactions/purchase/payment-voucher/report/:id',
    name: 'apps-transactions-purchase-payment-voucher-report',
    component: () => import('@/views/apps/transactions/purchase/payment-voucher/Report.vue'),
    meta: {
      pageTitle: 'apps.transactions.purchase.paymentVoucher.moduleName',
      action: 'read',
      resource: subjectAbility.TxPurchasePaymentVoucher
    }
  },
  // GOODS RECIPIENT
  {
    path: '/transactions/purchase/goods-recipient/list',
    name: 'apps-transactions-purchase-goods-recipient-list',
    component: () => import('@/views/apps/transactions/purchase/goods-recipient/Index.vue'),
    meta: {
      pageTitle: 'apps.transactions.purchase.goodsRecipient.moduleName',
      action: 'read',
      resource: subjectAbility.TxGoodsRecipient
    }
  },
  {
    path: '/transactions/purchase/goods-recipient/invoice',
    name: 'apps-transactions-purchase-goods-recipient-add',
    component: () => import('@/views/apps/transactions/purchase/goods-recipient/Form.vue'),
    meta: {
      pageTitle: 'apps.transactions.purchase.goodsRecipient.moduleName',
      action: 'create',
      resource: subjectAbility.TxGoodsRecipient
    },
    props: true
  },
  {
    path: '/transactions/purchase/goods-recipient/detail/:id',
    name: 'apps-transactions-purchase-goods-recipient-detail',
    component: () => import('@/views/apps/transactions/purchase/goods-recipient/Form.vue'),
    meta: {
      pageTitle: 'apps.transactions.purchase.goodsRecipient.moduleName',
      action: 'read',
      resource: subjectAbility.TxGoodsRecipient
    },
    props: true
  },
  // FORM PAYMENT - VENDOR
  {
    path: '/transactions/form/payment-vendor/list',
    name: 'apps-transactions-form-payment-vendor-list',
    component: () => import('@/views/apps/transactions/form/payment-vendor/Index.vue'),
    meta: {
      pageTitle: 'apps.formPayment.vendor.moduleName',
      action: 'read',
      resource: subjectAbility.TxFormPaymentVendor
    }
  },
  {
    path: '/transactions/form/payment-vendor/invoice',
    name: 'apps-transactions-form-payment-vendor-add-per-invoice',
    component: () => import('@/views/apps/transactions/form/payment-vendor/FormPerInvoice.vue'),
    meta: {
      pageTitle: 'apps.formPayment.vendor.moduleName',
      action: 'create',
      resource: subjectAbility.TxFormPaymentVendor
    },
    props: true
  },
  {
    path: '/transactions/form/payment-vendor/add',
    name: 'apps-transactions-form-payment-vendor-add',
    component: () => import('@/views/apps/transactions/form/payment-vendor/Form.vue'),
    meta: {
      pageTitle: 'apps.formPayment.vendor.moduleName',
      action: 'read',
      resource: subjectAbility.TxFormPaymentVendor
    }
  },
  {
    path: '/transactions/form/payment-vendor/detail/:id',
    name: 'apps-transactions-form-payment-vendor-detail',
    component: () => import('@/views/apps/transactions/form/payment-vendor/Form.vue'),
    meta: {
      pageTitle: 'apps.formPayment.vendor.moduleName',
      action: 'read',
      resource: subjectAbility.TxFormPaymentVendor
    }
  },
  //
  {
    path: '/transactions/form/payment-vendor/report/:id',
    name: 'apps-transactions-form-payment-vendor-report',
    component: () => import('@/views/apps/transactions/form/payment-vendor/Report.vue'),
    meta: {
      pageTitle: 'apps.formPayment.vendor.moduleName',
      action: 'read',
      resource: subjectAbility.TxFormPaymentVendor
    }
  },
  // FORM PAYMENT - ADVANCE
  {
    path: '/transactions/form/payment-advance/list',
    name: 'apps-transactions-form-payment-advance-list',
    component: () => import('@/views/apps/transactions/form/payment-advance/Index.vue'),
    meta: {
      pageTitle: 'apps.formPayment.advance.moduleName',
      action: 'read',
      resource: subjectAbility.TxFormPaymentAdvance
    }
  },
  {
    path: '/transactions/form/payment-advance/invoice',
    name: 'apps-transactions-form-payment-advance-add-per-invoice',
    component: () => import('@/views/apps/transactions/form/payment-advance/FormPerInvoice.vue'),
    meta: {
      pageTitle: 'apps.formPayment.advance.moduleName',
      action: 'create',
      resource: subjectAbility.TxFormPaymentAdvance
    },
    props: true
  },
  {
    path: '/transactions/form/payment-advance/add',
    name: 'apps-transactions-form-payment-advance-add',
    component: () => import('@/views/apps/transactions/form/payment-advance/Form.vue'),
    meta: {
      pageTitle: 'apps.formPayment.advance.moduleName',
      action: 'read',
      resource: subjectAbility.TxFormPaymentAdvance
    }
  },
  {
    path: '/transactions/form/payment-advance/detail/:id',
    name: 'apps-transactions-form-payment-advance-detail',
    component: () => import('@/views/apps/transactions/form/payment-advance/Form.vue'),
    meta: {
      pageTitle: 'apps.formPayment.advance.moduleName',
      action: 'read',
      resource: subjectAbility.TxFormPaymentAdvance
    }
  },
  {
    path: '/transactions/form/payment-advance/report/:id',
    name: 'apps-transactions-form-payment-advance-report',
    component: () => import('@/views/apps/transactions/form/payment-advance/Report.vue'),
    meta: {
      pageTitle: 'apps.formPayment.vendor.moduleNameAdvance',
      action: 'read',
      resource: subjectAbility.TxFormPaymentAdvance
    }
  },
  // FORM PAYMENT - AGENT
  {
    path: '/transactions/form/payment-agent/list',
    name: 'apps-transactions-form-payment-agent-list',
    component: () => import('@/views/apps/transactions/form/payment-agent/Index.vue'),
    meta: {
      pageTitle: 'apps.formPayment.agent.moduleName',
      action: 'read',
      resource: subjectAbility.TxFormPaymentAgent
    }
  },
  {
    path: '/transactions/form/payment-agent-advance/list',
    name: 'apps-transactions-form-payment-agent-advance-list',
    component: () => import('@/views/apps/transactions/form/payment-agent/Index.vue'),
    meta: {
      pageTitle: 'apps.formPayment.agent.moduleNameAdvance',
      action: 'read',
      resource: subjectAbility.TxFormPaymentAgent
    }
  },
  // {
  //   path: '/transactions/form/payment-agent/invoice',
  //   name: 'apps-transactions-form-payment-agent-add',
  //   component: () => import('@/views/apps/transactions/form/payment-agent/Form.vue'),
  //   meta: {
  //     pageTitle: 'apps.formPayment.agent.moduleName',
  //     action: 'create',
  //     resource: subjectAbility.TxFormPaymentAdvanceAgent
  //   },
  //   props: true
  // },
  {
    path: '/transactions/form/payment-agent/add',
    name: 'apps-transactions-form-payment-agent-add',
    component: () => import('@/views/apps/transactions/form/payment-agent/Form.vue'),
    meta: {
      pageTitle: 'apps.formPayment.agent.moduleName',
      action: 'read',
      resource: subjectAbility.TxFormPaymentAgent
    },
    props: true
  },
  {
    path: '/transactions/form/payment-agent/detail/:id',
    name: 'apps-transactions-form-payment-agent-detail',
    component: () => import('@/views/apps/transactions/form/payment-agent/Form.vue'),
    meta: {
      pageTitle: 'apps.formPayment.agent.moduleName',
      action: 'read',
      resource: subjectAbility.TxFormPaymentAgent
    }
  },
  {
    path: '/transactions/form/payment-agent/report/:id',
    name: 'apps-transactions-form-payment-agent-report',
    component: () => import('@/views/apps/transactions/form/payment-agent/Report.vue'),
    meta: {
      pageTitle: 'apps.formPayment.vendor.moduleNameAgent',
      action: 'read',
      resource: subjectAbility.TxFormPaymentAgent
    }
  },
  // END FORM
  // PURCHASE PAYMENT
  {
    path: '/transactions/purchase/payment/list',
    name: 'apps-transactions-purchase-payment-list',
    component: () => import('@/views/apps/transactions/purchase/payment/Index.vue'),
    meta: {
      pageTitle: 'apps.transactions.purchase.payment.moduleName',
      action: 'read',
      resource: subjectAbility.TxPurchasePayment
    }
  },
  {
    path: '/transactions/purchase/payment/listImage/:id',
    name: 'apps-transactions-purchase-payment-listImage',
    component: () => import('@/views/apps/transactions/purchase/payment/listImage.vue'),
    meta: {
      pageTitle: 'apps.transactions.purchase.payment.moduleName',
      action: 'read',
      resource: subjectAbility.TxPurchasePayment
    }
  },
  {
    path: '/transactions/purchase/payment/invoice',
    name: 'apps-transactions-purchase-payment-add-per-invoice',
    component: () => import('@/views/apps/transactions/purchase/payment/FormPerInvoice.vue'),
    meta: {
      pageTitle: 'apps.transactions.purchase.payment.moduleName',
      action: 'create',
      resource: subjectAbility.TxPurchasePayment
    },
    props: true
  },
  {
    path: '/transactions/purchase/payment/add',
    name: 'apps-transactions-purchase-payment-add',
    component: () => import('@/views/apps/transactions/purchase/payment/Form.vue'),
    meta: {
      pageTitle: 'apps.transactions.purchase.payment.moduleName',
      action: 'read',
      resource: subjectAbility.TxPurchasePayment
    }
  },
  // Payment with voucher
  {
    path: '/transactions/purchase/payment-per-voucher/add',
    name: 'apps-transactions-purchase-payment-per-voucher-add',
    component: () => import('@/views/apps/transactions/purchase/payment/FormPerVoucher.vue'),
    meta: {
      pageTitle: 'apps.transactions.purchase.payment.moduleName',
      action: 'read',
      resource: subjectAbility.TxPurchasePayment
    }
  },
  // outstanding
  {
    path: '/transactions/purchase/payment-per-voucher/add/:id',
    name: 'apps-transactions-purchase-payment-per-voucher-add-outs',
    component: () => import('@/views/apps/transactions/purchase/payment/FormPerVoucherInv'),
    meta: {
      pageTitle: 'apps.transactions.purchase.payment.moduleName',
      action: 'read',
      resource: subjectAbility.TxPurchasePayment
    }
  },
  {
    path: '/transactions/purchase/payment/detail/:id',
    name: 'apps-transactions-purchase-payment-detail',
    component: () => import('@/views/apps/transactions/purchase/payment/Form.vue'),
    meta: {
      pageTitle: 'apps.transactions.purchase.payment.moduleName',
      action: 'read',
      resource: subjectAbility.TxPurchasePayment
    }
  },
  // // Payment Cash Advance with form advance
  // {
  //   path: '/transactions/ap/cash-advance/list',
  //   name: 'apps-ap-cash-advance-list',
  //   component: () => import('@/views/apps/transactions/ap/cash-advance/Index.vue'),
  //   meta: {
  //     pageTitle: 'apps.ap.cashAdvance.moduleName',
  //     action: 'read',
  //     resource: subjectAbility.TxApCashAdvance
  //   }
  // },
  // {
  //   path: '/transactions/purchase/payment-per-voucher/add',
  //   name: 'apps-transactions-purchase-payment-per-voucher-add',
  //   component: () => import('@/views/apps/transactions/purchase/payment/FormPerVoucher.vue'),
  //   meta: {
  //     pageTitle: 'apps.ap.cashAdvance.moduleName',
  //     action: 'read',
  //     resource: subjectAbility.TxApCashAdvance
  //   }
  // },
  // {
  //   path: '/transactions/purchase/payment/detail/:id',
  //   name: 'apps-transactions-purchase-payment-detail',
  //   component: () => import('@/views/apps/transactions/purchase/payment/Form.vue'),
  //   meta: {
  //     pageTitle: 'apps.ap.cashAdvance.moduleName',
  //     action: 'read',
  //     resource: subjectAbility.TxApCashAdvance
  //   }
  // },

  // CASH ADVANCE REQUEST APPROVAL
  {
    path: '/transactions/cash-advance/request-approval',
    name: 'apps-transactions-cash-advance-request-approval-list',
    component: () => import('@/views/apps/transactions/cash-advance/request-approval/Index.vue'),
    meta: {
      pageTitle: 'apps.transactionsCashAdvanceRequestApproval.moduleName',
      action: 'read',
      resource: subjectAbility.TxCashAdvanceRequestApproval
    }
  },
  {
    path: '/transactions/cash-advance/request-approval/add',
    name: 'apps-transactions-cash-advance-request-approval-add',
    component: () => import('@/views/apps/transactions/cash-advance/request-approval/Form.vue'),
    meta: {
      pageTitle: 'apps.transactionsCashAdvanceRequestApproval.actions.create',
      action: 'create',
      resource: subjectAbility.TxCashAdvanceRequestApproval
    }
  },
  {
    path: '/transactions/cash-advance/request-approval/detail/:id',
    name: 'apps-transactions-cash-advance-request-approval-detail',
    component: () => import('@/views/apps/transactions/cash-advance/request-approval/Form.vue'),
    meta: {
      pageTitle: 'apps.transactionsCashAdvanceRequestApproval.actions.read',
      action: 'read',
      resource: subjectAbility.TxCashAdvanceRequestApproval
    }
  },

  // CASH ADVANCE REGISTER
  {
    path: '/transactions/cash-advance',
    name: 'apps-transactions-cash-advance-list',
    component: () => import('@/views/apps/transactions/cash-advance/register/Index.vue'),
    meta: {
      pageTitle: 'apps.transactionsCashAdvance.moduleName',
      action: 'read',
      resource: subjectAbility.TxCashAdvance
    }
  },
  {
    path: '/transactions/cash-advance/add',
    name: 'apps-transactions-cash-advance-add',
    component: () => import('@/views/apps/transactions/cash-advance/register/Form.vue'),
    meta: {
      pageTitle: 'apps.transactionsCashAdvance.actions.create',
      action: 'read',
      resource: subjectAbility.TxCashAdvance
    }
  },
  {
    path: '/transactions/cash-advance/detail/:id',
    name: 'apps-transactions-cash-advance-detail',
    component: () => import('@/views/apps/transactions/cash-advance/register/Form.vue'),
    meta: {
      pageTitle: 'apps.transactionsCashAdvance.actions.read',
      action: 'read',
      resource: subjectAbility.TxCashAdvance
    }
  },

  // CASH ADVANCE REALISATION
  // {
  //   path: '/transactions/cash-advance/realisation',
  //   name: 'apps-transactions-cash-advance-realisation-list',
  //   component: () => import('@/views/apps/transactions/cash-advance/realisation/Index.vue'),
  //   meta: {
  //     pageTitle: 'apps.transactionsCashAdvance.moduleName',
  //     action: 'read',
  //     resource: subjectAbility.TxCashAdvance
  //   }
  // },
  // {
  //   path: '/transactions/cash-advance/realisation/add',
  //   name: 'apps-transactions-cash-advance-realisation-add',
  //   component: () => import('@/views/apps/transactions/cash-advance/realisation/Form.vue'),
  //   meta: {
  //     pageTitle: 'apps.transactionsCashAdvance.actions.create',
  //     action: 'read',
  //     resource: subjectAbility.TxCashAdvance
  //   }
  // },
  {
    path: '/transactions/cash-advance/realisation/detail/:id',
    name: 'apps-transactions-cash-advance-realisation-detail',
    component: () => import('@/views/apps/transactions/cash-advance/realisation/Form.vue'),
    meta: {
      pageTitle: 'apps.transactionsCashAdvance.actions.read',
      action: 'read',
      resource: subjectAbility.TxCashAdvance
    }
  },
  // REFUND
  {
    path: '/transactions/cash-advance/refund/detail/:id',
    name: 'apps-transactions-cash-advance-refund-detail',
    component: () => import('@/views/apps/transactions/cash-advance/refund/Form.vue'),
    meta: {
      pageTitle: 'apps.transactionsCashAdvance.singular.refund',
      action: 'read',
      resource: subjectAbility.TxCashAdvance
    }
  },

  // CASH ADVANCE REQUEST
  {
    path: '/transactions/cash-advance/request',
    name: 'apps-transactions-cash-advance-request-list',
    component: () => import('@/views/apps/transactions/cash-advance/request/Index.vue'),
    meta: {
      pageTitle: 'apps.transactionsCashAdvanceRequest.moduleNameRequest',
      action: 'read',
      resource: subjectAbility.TxCashAdvanceRequest
    }
  },
  {
    path: '/transactions/cash-advance/request/add',
    name: 'apps-transactions-cash-advance-request-add',
    component: () => import('@/views/apps/transactions/cash-advance/request/Form.vue'),
    meta: {
      pageTitle: 'apps.transactionsCashAdvanceRequest.actions.create',
      action: 'create',
      resource: subjectAbility.TxCashAdvanceRequest
    }
  },
  {
    path: '/transactions/cash-advance/request/detail/:id',
    name: 'apps-transactions-cash-advance-request-detail',
    component: () => import('@/views/apps/transactions/cash-advance/request/Form.vue'),
    meta: {
      pageTitle: 'apps.transactionsCashAdvanceRequest.actions.read',
      action: 'read',
      resource: subjectAbility.TxCashAdvanceRequest
    }
  },

  // CLOSING
  {
    path: '/transactions/closing',
    name: 'apps-transactions-closing-list',
    component: () => import('@/views/apps/transactions/closing/Index.vue'),
    meta: {
      pageTitle: 'apps.transactionsClosing.moduleName',
      action: 'read',
      resource: subjectAbility.TxClosing
    }
  },
  {
    path: '/transactions/closing/detail/:id',
    name: 'apps-transactions-closing-detail',
    component: () => import('@/views/apps/transactions/closing/Detail.vue'),
    meta: {
      pageTitle: 'apps.transactionsClosing.singular.profitLoss',
      action: 'read',
      resource: subjectAbility.TxClosing
    }
  },

  // BUDGETING
  {
    path: '/transactions/budgeting',
    name: 'apps-transactions-budgeting-list',
    component: () => import('@/views/apps/transactions/Budgeting/Index.vue'),
    meta: {
      pageTitle: 'apps.budgeting.moduleName',
      action: 'read',
      resource: subjectAbility.TxBudgeting
    }
  },
  {
    path: '/transactions/budgeting/add',
    name: 'apps-transactions-budgeting-add',
    component: () => import('@/views/apps/transactions/Budgeting/Form.vue'),
    meta: {
      pageTitle: 'apps.budgeting.moduleName',
      action: 'read',
      resource: subjectAbility.TxBudgeting
    }
  },
  {
    path: '/transactions/budgeting/detail/:id',
    name: 'apps-transactions-budgeting-detail',
    component: () => import('@/views/apps/transactions/Budgeting/Form.vue'),
    meta: {
      pageTitle: 'apps.budgeting.moduleName',
      action: 'read',
      resource: subjectAbility.TxBudgeting
    }
  }

]
