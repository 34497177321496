/* eslint-disable no-unused-vars */
import { signIn, signUp, forgotPassword } from '@/auth/auth'
import { getToken, setToken, removeToken } from '@/utils/auth'

export default {
  namespaced: true,
  state: {
    id: null,
    token: getToken(),
    name: 'Artos Dev',
    email: 'artos@odisys.id',
    companies: [],
    currentCompany: null,
    avatar: null,
    isNational: false
  },
  getters: {
  },
  mutations: {
    SET_ID: (state, id) => {
      state.id = id
    },
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_EMAIL: (state, email) => {
      state.email = email
    },
    SET_COMPANIES: (state, companies) => {
      state.companies = companies
    },
    SET_CURRENT_COMPANY: (state, currentCompany) => {
      state.currentCompany = currentCompany
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_IS_NATIONAL: (state, isNational) => {
      state.isNational = isNational
    }
  },
  actions: {
    setUserData ({ commit, dispatch }, payload) {
      return new Promise(resolve => {
        commit('SET_TOKEN', payload.token)
        commit('SET_ID', payload.data.user.id)
        commit('SET_NAME', payload.data.user.user_name)
        commit('SET_EMAIL', payload.data.user.user_email)
        commit('SET_CURRENT_COMPANY', payload.data.user.active_company)
        commit('SET_COMPANIES', payload.data.companies)
        commit('SET_AVATAR', payload.data.avatar)
        commit('SET_IS_NATIONAL', payload.data.user.is_national)
        commit('app/SET_MENU_LISTS', payload.data.menus, { root: true })
        setToken(payload.token)
        resolve()
      })
    },

    // user login
    signIn ({ commit }, payload) {
      const { $toast, component, data } = payload.toasts
      return new Promise((resolve, reject) => {
        signIn(payload.data)
        .then(response => {
            commit('SET_TOKEN', response.token)
            commit('SET_ID', response.data.user.id)
            commit('SET_NAME', response.data.user.user_name)
            commit('SET_EMAIL', response.data.user.user_email)
            commit('SET_CURRENT_COMPANY', response.data.user.active_company)
            commit('SET_COMPANIES', response.data.companies)
            commit('SET_AVATAR', response.data.user.avatar)
            commit('SET_IS_NATIONAL', response.data.user.is_national)
            commit('app/SET_MENU_LISTS', response.data.menus, { root: true })
            setToken(response.token)
            resolve(response.data)
          })
          .catch(({ message }) => {
            $toast({
              component,
              position: 'top-right',
              props: {
                title: 'Error',
                icon: 'AlertCircleIcon',
                variant: 'danger',
                text: message
              }
            })
            reject(message)
          })
      })
    },

    // user signOut
    async signOut ({ commit }, payload) {
      if (!payload.context) {
        console.error('dispatch action user/signOut requires payload.context!')
        return null
      }

      // Delete the token first
      await commit('SET_TOKEN', '')
      await removeToken()

      // Redirect to Login
      await payload.context.$router.push({ name: 'login' })

      // Finally delete all user data
      await localStorage.removeItem('vuex')
      await localStorage.removeItem('userData')
      await commit('SET_ID', '')
      await commit('SET_NAME', '')
      await commit('SET_EMAIL', '')
      await commit('SET_CURRENT_COMPANY', null)
      await commit('SET_COMPANIES', [])
      await commit('SET_IS_NATIONAL', '')
      await commit('SET_AVATAR', null)
    },

    // remove token
    resetToken ({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '')
        removeToken()
        resolve()
      })
    },

    // register user
    signUp ({ commit }, payload) {
      const { $toast, component, data } = payload.toasts
      return new Promise((resolve, reject) => {
        signUp(payload.data)
          .then(response => {
            if (response.errors) {
              $toast({
                component,
                position: 'top-right',
                props: {
                  title: 'Error',
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                  text: response.message
                }
              })
              reject(response.message)
            } else {
              $toast({
                component,
                position: 'top-right',
                props: {
                  title: 'success',
                  icon: 'CheckIcon',
                  variant: 'success',
                  text: response.message
                }
              })
              resolve(response)
            }
          })
          .catch(({ message }) => {
            $toast({
              component,
              position: 'top-right',
              props: {
                title: 'Error',
                icon: 'AlertCircleIcon',
                variant: 'danger',
                text: message
              }
            })
            reject(message)
          })
      })
    },

    // forgot password
    forgotPassword ({ commit }, payload) {
      const { $toast, component, data } = payload.toasts
      return new Promise((resolve, reject) => {
        forgotPassword(payload.data)
          .then(response => {
            $toast({
              component,
              position: 'top-right',
              props: {
                title: 'success',
                icon: 'CheckIcon',
                variant: 'success',
                text: response.message
              }
            })
            resolve(response)
          })
          .catch(({ message }) => {
            $toast({
              component,
              position: 'top-right',
              props: {
                title: 'Error',
                icon: 'AlertCircleIcon',
                variant: 'danger',
                text: message
              }
            })
            reject(message)
          })
      })
    }
  }
}
